import React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {URLS} from "../../configs/routes_url";
import NotFound from "../pages/Error/NotFound";

const currentLang = localStorage.getItem('i18nextLng')

const listURL = [
    `/${currentLang}${URLS.REPORT_FUND_SETTLEMENT}`,
    `/${currentLang}${URLS.REPORT_FUND_OUT}`,
    `/${currentLang}${URLS.REPORT_TOTAL}`,
    `/${currentLang}${URLS.REPORT_CHANNEL}`,
    `/${currentLang}${URLS.PROFILE}`,
    `/${currentLang}${URLS.CHANGE_PASSWORD}`,
    `/${currentLang}${URLS.NOTIFICATION}`,
]

export interface StateFromProps {
}

export interface DispatchFromProps {
}

export type OwnProps = RouteComponentProps<{}>;

type Props = any | StateFromProps & DispatchFromProps & OwnProps;

type State = {};

class AccessControl extends React.Component<Props, State> {

    render() {
        const {Component, Data, ...rest} = this.props
        if (Data.is_agent_access_to_report) {
            if (!listURL.includes(rest.location.pathname)) {
                return (
                    <NotFound/>
                )
            } else {
                return (
                    <Component {...rest}/>
                )
            }
        } else {
            return (
                <Component {...rest}/>
            )
        }
    }
}

export default AccessControl;
