import {fromJS} from 'immutable';
import {ReduxActionTypes} from './action-type';

const initialState: any = fromJS({
    reportFundTransferList: [],
    reportChannelList: [],
    reportFundOutList: [],
    reportFundCombinationList: [],
    reportTotalList: [],

})

export default function (state = initialState, action: any) {
    switch (action.type) {
        case ReduxActionTypes.SET_REPORT_CHANNEL_LIST:
            return state.set('reportChannelList', action.list)
        case ReduxActionTypes.SET_REPORT_FUND_TRANSFER_LIST:
            return state.set('reportFundTransferList', action.list)
        case ReduxActionTypes.SET_REPORT_FUND_OUT_LIST:
            return state.set('reportFundOutList', action.list)
        case ReduxActionTypes.SET_REPORT_FUND_COMBINATION_LIST:
            return state.set('reportFundCombinationList', action.list)
        case ReduxActionTypes.SET_REPORT_TOTAL_LIST:
            return state.set('reportTotalList', action.list)
        default:
            return state;
    }
}
