import React, {Suspense} from 'react'
import {PublicRoutes, SecureRoutes} from "./routes";
import NotFound from "../../../components/pages/Error/NotFound";
import {Fallback} from "../../../components/layouts/FallBack";
import PublicRoute from "../../../components/routes/Public";
import SecureRoute from "../../../components/routes/Secure";
import {withRouter} from "react-router-dom";
import RootState from "../../../modules/rootState";
import {connect} from "react-redux";
import {stateAccountSelectors} from "../../../modules/accounts";
import {sagaActions as commonSagaAction} from "../../../modules/common/action";

type Props = any;
type State = {};

class AgentRoutes extends React.Component<Props, State> {
    checkPageNotFound = () => {
        const path = this.props.history.location.pathname
        const urlLangParam = (this.props.match.params as any).lang
        let route = path.replace(`/${urlLangParam}`, '')
        return !PublicRoutes.some(x => x.href === route) && !SecureRoutes.some(x => x.href === route)
    }

    render() {
        let pageNotFound = this.checkPageNotFound()

        return (
            <>
                {pageNotFound && <NotFound/>}
                {!pageNotFound && (
                    <Suspense fallback={<Fallback/>}>
                        {PublicRoutes.map((value: any, index) => (
                            <PublicRoute key={index} exact path={`/:lang${value.href}`}
                                         component={value.component}/>
                        ))}

                        {SecureRoutes.map((value: any, index) => (
                            <SecureRoute key={index} exact path={`/:lang${value.href}`}
                                         component={value.component}/>
                        ))}
                    </Suspense>
                )}
            </>
        )
    }
}

const mapStateToProps = (rootState: RootState) => ({
    isAuthenticated: stateAccountSelectors.getAuthenticationStatus(rootState),
});

const mapDispatchToProps = (dispatch: any) => ({
    redirect: (path: string) => dispatch(commonSagaAction.redirect(path))
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AgentRoutes)) as any