import {URLS} from "../../../configs/routes_url";
import {lazy} from "react";

const delayLazy = 0;

const DashboardLazy = lazy(async () => {
    await new Promise(resolve => setTimeout(resolve, delayLazy));
    return import('../../../components/pages/Dashboard/Dashboard.container');
});

const SignInLazy = lazy(async () => {
    await new Promise(resolve => setTimeout(resolve, delayLazy));
    return import('../../../components/pages/SignIn/SignIn.container');
});

const ProfileLazy = lazy(async () => {
    await new Promise(resolve => setTimeout(resolve, delayLazy));
    return import('../../../components/pages/Profile/Profile.container');
});

const ChangePasswordLazy = lazy(async () => {
    await new Promise(resolve => setTimeout(resolve, delayLazy));
    return import('../../../components/pages/ChangePassword/ChangePassword.container');
});

const EditProfileLazy = lazy(async () => {
    await new Promise(resolve => setTimeout(resolve, delayLazy));
    return import('../../../components/pages/EditProfile/EditProfile.container');
});

const NotificationLazy = lazy(async () => {
    await new Promise(resolve => setTimeout(resolve, delayLazy));
    return import('../../../components/pages/Notification/Notification.container');
});

const MessageLazy = lazy(async () => {
    await new Promise(resolve => setTimeout(resolve, delayLazy));
    return import('../../../components/pages/Message/Message.container');
});

const DepositLazy = lazy(async () => {
    await new Promise(resolve => setTimeout(resolve, delayLazy));
    return import('../../../components/pages/Deposit/Deposit.container');
});

const FundOutLazy = lazy(async () => {
    await new Promise(resolve => setTimeout(resolve, delayLazy));
    return import('../../../components/pages/FundOut/FundOut.container');
});

const FundOutHistoryLazy = lazy(async () => {
    await new Promise(resolve => setTimeout(resolve, delayLazy));
    return import('../../../components/pages/FundOutHistory/FundOutHistory.container');
});

const SettlementHistoryLazy = lazy(async () => {
    await new Promise(resolve => setTimeout(resolve, delayLazy));
    return import('../../../components/pages/SettlementHistory/SettlementHistory.container');
});

const SettlementLazy = lazy(async () => {
    await new Promise(resolve => setTimeout(resolve, delayLazy));
    return import('../../../components/pages/Settlement/Settlement.container');
});

const CreateBankAccountLazy = lazy(async () => {
    await new Promise(resolve => setTimeout(resolve, delayLazy));
    return import('../../../components/pages/AgentBankAccount/AgentBankAccount.container');
});

const ListBankAccountLazy = lazy(async () => {
    await new Promise(resolve => setTimeout(resolve, delayLazy));
    return import('../../../components/pages/AgentBankAccountList/AgentBankAccountList.container');
});

const ReportFundTransferLazy = lazy(async () => {
    await new Promise(resolve => setTimeout(resolve, delayLazy));
    return import('../../../components/pages/Report/FundTransfer/FundTransfer.container');
});

const ReportFundCombinationLazy = lazy(async () => {
    await new Promise(resolve => setTimeout(resolve, delayLazy));
    return import('../../../components/pages/Report/FundCombination/FundCombination.container');
});

const ReportFundOutLazy = lazy(async () => {
    await new Promise(resolve => setTimeout(resolve, delayLazy));
    return import('../../../components/pages/Report/FundOut/FundOut.container');
});

const ReportTotalLazy = lazy(async () => {
    await new Promise(resolve => setTimeout(resolve, delayLazy));
    return import('../../../components/pages/Report/Total/Total.container');
});

const ReportChannelLazy = lazy(async () => {
    await new Promise(resolve => setTimeout(resolve, delayLazy));
    return import('../../../components/pages/Report/Channel/Channel.container');
});

const UsersLazy = lazy(async () => {
    await new Promise(resolve => setTimeout(resolve, delayLazy));
    return import('../../../components/pages/Users/Users.container');
});

const BalanceLogLazy = lazy(async () => {
    await new Promise(resolve => setTimeout(resolve, delayLazy));
    return import('../../../components/pages/BalanceLog/BalanceLog.container');
});

export const PublicRoutes = [
    {
        href: URLS.SIGN_IN,
        component: SignInLazy
    }
]
export const SecureRoutes = [
    {
        href: URLS.DASHBOARD,
        component: DashboardLazy
    }, {
        href: URLS.PROFILE,
        component: ProfileLazy
    }, {
        href: URLS.CHANGE_PASSWORD,
        component: ChangePasswordLazy
    }, {
        href: URLS.EDIT_PROFILE,
        component: EditProfileLazy
    }, {
        href: URLS.NOTIFICATION,
        component: NotificationLazy
    }, {
        href: URLS.MESSAGE,
        component: MessageLazy
    }, {
        href: URLS.DEPOSIT,
        component: DepositLazy
    }, {
        href: URLS.FUND_OUT,
        component: FundOutLazy
    }, {
        href: URLS.FUND_OUT_HISTORY,
        component: FundOutHistoryLazy
    }, {
        href: URLS.CREATE_BANK_ACCOUNT,
        component: CreateBankAccountLazy
    }, {
        href: URLS.LIST_BANK_ACCOUNT,
        component: ListBankAccountLazy
    }, {
        href: URLS.SETTLEMENT,
        component: SettlementLazy
    }, {
        href: URLS.SETTLEMENT_HISTORY,
        component: SettlementHistoryLazy
    }, {
        href: URLS.REPORT_FUND_OUT,
        component: ReportFundOutLazy
    }, {
        href: URLS.REPORT_FUND_SETTLEMENT,
        component: ReportFundTransferLazy
    }, {
        href: URLS.REPORT_FUND_COMBINATION,
        component: ReportFundCombinationLazy
    }, {
        href: URLS.REPORT_TOTAL,
        component: ReportTotalLazy
    }, {
        href: URLS.REPORT_CHANNEL,
        component: ReportChannelLazy
    }, {
        href: URLS.USERS,
        component: UsersLazy
    }, {
        href: URLS.BALANCE_LOG,
        component: BalanceLogLazy
    }
]